import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Divider } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InfoCircle } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import HeaderView from '../../common/HeaderView';
import LoadableView from '../../common/LoadableView';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';

const CheckoutInvoices = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search.toLowerCase());
  const secureId = searchParams.get('secureid');
  const [formState, setFormState] = useState({ isLoading: false });
  const [loadingSecureId, setLoadingSecureId] = useState(false);
  const { invoiceId } = useParams();
  const {
    state,
    fetchRegFormSubmissionInvoice,
    checkoutInvoice,
    fetchCheckoutInvoiceResult
  } = useContext(RegistrationContext);
  const isLoading =
    !formState.hasInitialized ||
    state.regFormSubmissionInvoice.loading ||
    !state.regFormSubmissionInvoice.value ||
    loadingSecureId;
  const invoicePayment = state.regFormSubmissionInvoice.value;

  const currencyAbbr = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      invoicePayment?.regAssociation.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useEffect(() => {
    if (invoiceId) {
      fetchRegFormSubmissionInvoice(invoiceId, null, () =>
        navigate('/checkout/cart')
      );
      setFormState({ hasInitialized: false, isLoading: false });
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoicePayment) {
      if (
        invoicePayment.regFormSubmissionInvoice.status !== 'PENDING' &&
        !secureId
      ) {
        navigate('/checkout/cart');
      }
      else if (secureId) {
        setLoadingSecureId(true);
        fetchCheckoutInvoiceResult(
          { invoiceId, secureId },
          (result) => {
            triggerNotification('Invoice Paid', 'Success', 'green');
            navigate('/');
          },
          (e) => {
            triggerNotification(e);
            navigate(pathname);
          }
        );
      }
      setFormState({ hasInitialized: true, isLoading: false });
    }
  }, [invoicePayment]);

  return (
    <LoadableView isLoading={isLoading}>
      <HeaderView>
        {!isLoading && (
          <AppCard style={{ maxWidth: 500, margin: 'auto', width: '100%' }}>
            <AppStack style={{ gap: 15 }}>
              <AppTitle order={3}>Invoice Pending</AppTitle>
              <AppStack style={{ gap: 20 }}>
                <Alert
                  color="blue"
                  icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                  variant="outline"
                >
                  <AppText>
                    You have an invoice from{' '}
                    <b>{invoicePayment.regAssociation.association.name}</b>{' '}
                    thats pending payment.
                  </AppText>
                </Alert>
                <Divider />

                <AppFlexbox
                  style={{ flexWrap: 'nowrap', gap: 10, marginTop: 10 }}
                >
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    {invoicePayment.regFormSubmissionInvoice.description}
                  </AppText>
                  <Divider style={{ flex: 1 }} variant="dotted" />
                  <AppText style={{ fontSize: 16 }}>
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={invoicePayment.amount}
                    />{' '}
                    {currencyAbbr}
                  </AppText>
                </AppFlexbox>

                <Divider />

                <AppFlexbox style={{ justifyContent: 'center' }}>
                  <Button
                    color="blue"
                    loading={formState.isLoading}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        isLoading: true
                      });
                      checkoutInvoice(
                        invoicePayment.regFormSubmissionInvoice
                          .pkRegFormSubmissionInvoice,
                        (sessionUrl) => {
                          if (sessionUrl) {
                            window.location.href = sessionUrl;
                          }
                        },
                        (e) => {
                          triggerNotification(e);
                          setFormState({
                            ...formState,
                            isLoading: false
                          });
                        }
                      );
                    }}
                    style={{ flex: 1, maxWidth: 250 }}
                  >
                    Pay Now
                  </Button>
                </AppFlexbox>
              </AppStack>
            </AppStack>
          </AppCard>
        )}
      </HeaderView>
    </LoadableView>
  );
};

export default CheckoutInvoices;
